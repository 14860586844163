/* Styling for custom select */
.custom-select {
  position: relative;
}

.select-selected {
  background-color: #f8f8f8;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 120px; /* Adjust the minimum width as needed */
  text-align: center; /* Center the text */
  border: 1px solid #ccc;
  transition: background-color 0.3s ease, border-color 0.3s ease; 
  margin-right: 2px;
  border: 10px;
  border-radius: 10px;
  border-color: black;
}

.select-selected:focus {
  outline: none;
  border-color: #7e7e7e; 
}

@media (max-width: 768px) {
  .select-selected {
    min-width: 100px; 
  }
}


.select-items {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 20%;
  max-height: 350px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #ccc;
  z-index: 1;
}

.select-option {
  padding: 12px 16px;
  cursor: pointer;
}

.select-option:hover {
  background-color: #ddd;
}

.select-hide {
  display: none;
}
